import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PersonalBlog from '../containers/Blog';
import { ThemeProvider } from '@material-ui/styles';
import materialTheme from '../theme/material-theme';

const PersonalBlogPage = () => {
  return (
    <ThemeProvider theme={materialTheme}>
      <Layout>
        <SEO title="Blog" description="Home page for all blog posts from Sweet Dreams Jellybean. Certified sleep consultant." />
        <PersonalBlog />
      </Layout>
    </ThemeProvider>
  );
};

export default PersonalBlogPage;
