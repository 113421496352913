import * as React from 'react';
import theme from '../../theme/material-theme';
import PersonalBlogWrapper from './style';
import Posts from './Posts';
import Banner from './Banner';
import { ThemeProvider } from '@material-ui/styles';

type PersonalBlogProps = {};

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <PersonalBlogWrapper {...props}>
        <Banner />
        <Posts />
      </PersonalBlogWrapper>
    </ThemeProvider>
  );
};

export default PersonalBlog;
